.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#name {
	font-size: 48px;
	color: white;
	animation: SlideIn 400ms linear;
	animation-fill-mode: both;
	animation-delay: 0.1s;
}

#fname {
	font-weight: 500;
}

#lname {
	font-weight: 300;
}

.badges {
	width: 25%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

a {
	color: white;
	width: 25%;
	margin-right: 20%;
}

i {
	animation: SlideIn 400ms linear;
	animation-fill-mode: backwards;
}

a:nth-child(4) {
	margin-right: 0;
}

a:nth-child(1) i {
	animation-delay: 0.3s;
}

a:nth-child(2) i {
	animation-delay: 0.4s;
}

a:nth-child(3) i {
	animation-delay: 0.5s;
}

a:nth-child(4) i {
	animation-delay: 0.6s;
}

@keyframes SlideIn {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}

	80% {
		transform: translateY(10px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@media (max-width: 600px) {
	hr {
		border-top: 1.5px solid white !important;
	}
	#name {
		font-size: 65px;
	}
	.container {
		justify-content: center;
	}
	.badges {
		display: none;
	}
}

@media (min-width: 2000px) {
	#name {
		font-size: 80px;
	}
	i {
		font-size: 3em !important;
	}
}
