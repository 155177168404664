video {
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1.5px solid darkslategray;
}

.projectCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  width: 45%;
  height: 85%;
  background-color: rgb(115, 129, 137);
  border: 1.5px solid darkslategray;
  overflow-y: auto;
}

.vidContainer {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
}

.details {
  position: relative;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
}

h1 {
  margin-bottom: 0px;
  margin-top: 1%;
  color: white;
}

p {
  /* word-break: normal; */
  color: white;
  width: 100%;
  opacity: 0.65;
}

.links {
  width: 100%;
  display: flex;
}

.links .live {
  background: #00b8ff;
}

.links .source {
  background: #ffbb74;
}

.links a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 50%;
  font-size: 16px;
  color: black;
  padding: 2%;
  outline: none;
  text-decoration: none;
  background: white;
}
a i {
  font-size: 12px;
  margin-left: 2%;
  height: 45%;
  pointer-events: none;
}

.tools {
  margin: 2% 0%;
  width: 100%;
  text-align: center;
}

.tool {
  font-size: 12px;
  color: black;
  background: white;
  border-radius: 5px;
  margin: 0% 1%;
  padding: 1%;
}

@media (hover: hover) {
  .links a:hover {
    opacity: 0.5;
  }
}

@-moz-document url-prefix() {
  video {
    height: unset;
  }
}

@supports (-ms-ime-align: auto) {
  video {
    width: unset;
  }
}

@media (max-width: 600px) {
  .projectCard {
    width: 100% !important;
  }
  .vidContainer {
    height: 40% !important;
  }
  .details {
    height: 60% !important;
  }
  .links a:active {
    opacity: 0.5;
  }
}

@media (max-width: 1200px) {
  .projectCard {
    width: 80%;
  }
  .vidContainer {
    height: 50%;
  }
  .details {
    height: 50%;
  }
}
@media (min-width: 1200px) {
  .links a {
    padding: 1%;
  }
}

@media (min-width: 1800px) {
  .details {
    font-size: 1.6rem;
  }
  .links a {
    font-size: 1.5em;
  }
  .links i {
    font-size: 1em !important;
    height: 65%;
  }
  .tools {
    margin: 0;
  }
  p {
    margin-top: 2%;
  }
}

@media (min-width: 2000px) {
  .projectCard {
    width: 35%;
  }
}
